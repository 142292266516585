import React from 'react';
import Icon from '@material-ui/core/Icon';

export default function Success() {
  return (
    <Icon
      style={{
        display: 'block',
        margin: 'auto',
        position: 'relative',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        color: '#20c820',
        fontSize: '80px',
      }}>
      check_circle
    </Icon>
  );
}
