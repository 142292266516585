import React from 'react';
import { makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//////////////////////////////////////// COMPONENT ////////////////////////////////////////
export default function AppraisalsFilter1(props) {
  const cls = useStyles();

  const { range, start, end, onRangeChange, onDateChange, disablePickers } = props;

  const handleRangeChange = event => {
    onRangeChange(event.target.value);
  };

  const handleDateChange = name => event => {
    onDateChange(event, name);
  };

  return (
    <>
      <div className={cls.main}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                select
                fullWidth
                label='Quarterly Range'
                variant='outlined'
                margin='none'
                value={range}
                onChange={handleRangeChange}
              >
                <MenuItem value={`this`}>This Quarter (Last 3 Months)</MenuItem>
                <MenuItem value={`last`}>Last Quarter (3-6 Months Ago)</MenuItem>
                <MenuItem value={`custom`}>Custom (Max 3-Month Quarter)</MenuItem>
              </TextField>
            </Grid>

            <Grid item md={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                // maxDate={end}
                variant='inline'
                orientation='portrait'
                format='MM/dd/yyyy'
                label='Start Date (From)'
                margin='none'
                inputVariant='outlined'
                value={start}
                onChange={handleDateChange(`start`)}
                className={cls.date}
              />
            </Grid>

            <Grid item md={3} xs={6}>
              <DatePicker
                disabled={disablePickers}
                autoOk
                // minDate={start}
                variant='inline'
                orientation='portrait'
                format='MM/dd/yyyy'
                label='End Date (To)'
                margin='none'
                inputVariant='outlined'
                value={end}
                onChange={handleDateChange(`end`)}
                className={cls.date}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

//////////////////////////////////////// STYLES ////////////////////////////////////////
const useStyles = makeStyles(theme => ({
  main: {
    marginBottom: theme.spacing(2),
  },
  date: {
    width: '100%',
  },
}));
