import React from 'react';
import { makeStyles, Typography, DialogContent } from '@material-ui/core';

////////// COMPONENT //////////
export default function ModalContent(props) {
  const cls = useStyles();
  const { className, subtitleClassName, subtitle, disableDividers } = props;
  return (
    <DialogContent dividers={!disableDividers} className={cls.padding + ' ' + className}>
      {subtitle && (
        <>
          <Typography className={cls.subtitle + ' ' + subtitleClassName}>{subtitle}</Typography>
          <div className={cls.break} />
        </>
      )}
      {props.children}
    </DialogContent>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  padding: {
    padding: theme.spacing(2),
    margin: 0,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
