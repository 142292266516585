import React from "react";

import { makeStyles, Grid } from '@material-ui/core/';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AutoRefresh from '../../reusable/AutoRefresh';

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  date: {
    width: '100%',
  },
}));

////////// COMPONENT //////////
export default function ARIndexFilter(props) {
  const cls = useStyles();

  const { start, end, onChange, refetch } = props;

  const handleDateChange = name => event => {
    onChange(event, name);
  }

  return (<>
    <div className={cls.main}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePicker
              autoOk
              maxDate={end}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="Start Date"
              margin="dense"
              // inputVariant="outlined"
              value={start}
              onChange={handleDateChange(`start`)}
              className={cls.date}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <DatePicker
              autoOk
              minDate={start}
              orientation="portrait"
              format="MM/dd/yyyy"
              label="End Date"
              margin="dense"
              // inputVariant="outlined"
              value={end}
              onChange={handleDateChange(`end`)}
              className={cls.date}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <AutoRefresh refetch={refetch} interval={60000} persistAs="invoices" />
          </Grid>

        </Grid>

      </MuiPickersUtilsProvider>
    </div>
  </>)
}